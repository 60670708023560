.wait {
    width: 100%;
    background-color: #e9ecef;
    color: #212529;
}

.wait:hover {
    width: 100%;
    background-color: #ffffff;
    color: #6c757d !important;
    border-color: #212529 !important;
}

.pause {
    width: 100%;
    background-color: #6c757d;
    color: #ffffff;
}

.pause:hover {
    width: 100%;
    background-color: #ffffff;
    color: #6c757d !important;
    border-color: #6c757d !important;
}

.start {
    width: 100%;
    background-color: #1677ff;
    color: #ffffff;
}

.start:hover {
    width: 100%;
    background-color: #ffffff;
    color: #1677ff !important;
    border-color: #1677ff !important;
}

.outsourcing {
    width: 100%;
    background-color: #0dcaf0;
    color: #ffffff;
}

.outsourcing:hover {
    width: 100%;
    background-color: #ffffff;
    color: #0dcaf0 !important;
    border-color: #0dcaf0 !important;
}

.end {
    width: 100%;
    background-color: #198754;
    color: #ffffff;
}

.end:hover {
    width: 100%;
    background-color: #ffffff;
    color: #198754 !important;
    border-color: #198754 !important;
}

.emergency {
    width: 100%;
    background-color: #dc3545;
    color: #ffffff;
}

.emergency:hover {
    width: 100%;
    background-color: #ffffff;
    color: #dc3545 !important;
    border-color: #dc3545 !important;
}




.wait-modal {
    width: 100%;
    background-color: #ffffff;
    color: #212529;
}

.wait-modal:hover {
    width: 100%;
    background-color: #212529;
    color: #ffffff !important;
    border-color: #212529 !important;
}

.pause-modal {
    width: 100%;
    background-color: #ffffff;
    color: #6c757d;
}

.pause-modal:hover {
    width: 100%;
    background-color: #6c757d;
    color: #ffffff !important;
    border-color: #ffffff !important;
}

.start-modal {
    width: 100%;
    background-color: #ffffff;
    color: #1677ff;
}

.start-modal:hover {
    width: 100%;
    background-color: #1677ff;
    color: #ffffff !important;
    border-color: #ffffff !important;
}

.outsourcing-modal {
    width: 100%;
    background-color: #ffffff;
    color: #0dcaf0;
}

.outsourcing-modal:hover {
    width: 100%;
    background-color: #0dcaf0;
    color: #ffffff !important;
    border-color: #ffffff !important;
}

.end-modal {
    width: 100%;
    background-color: #ffffff;
    color: #198754;
}

.end-modal:hover {
    width: 100%;
    background-color: #198754;
    color: #ffffff !important;
    border-color: #ffffff !important;
}

.emergency-modal {
    width: 100%;
    background-color: #ffffff;
    color: #dc3545;
}

.emergency-modal:hover {
    width: 100%;
    background-color: #dc3545;
    color: #ffffff !important;
    border-color: #ffffff !important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}