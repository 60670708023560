.Align-Center {
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.login-image {
  width: 160px;
  height: 40px;
  /* object-fit: cover; */
}