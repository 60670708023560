.header{
  background: rgb(41, 89, 138);
  width: 100%;
  display: flex;
  color: white;
  padding: 20px;
  font-weight: 600;
  font-size: 20px;
  min-height: 0vh;
}

.header-image{
  width: 120px;
  height: 30px;
  margin-right: 20px;
  /* object-fit: cover; */
}

.ant-menu-item-selected {
  /* background-color: #001529 !important; */
  background-color: #306396 !important;
  color: #ffffff !important;
  font-weight:500;

  /* border-bottom: 3px solid #1677ff; */
}
.ant-menu-submenu-selected {
  background-color: #306396 !important;
  color: #ffffff !important;
  font-weight:500;
}
