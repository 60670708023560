.font-gMarket{
    font-family: 'GmarketSansMedium';
}
/* 

.mx-0 {
    font-family:'GmarketSansMedium';
    width: 30%;
    margin-top: 20px;
}

.mx-0-mobile {
    font-family:'GmarketSansMedium';
    width: 70%;
    margin-top: 20px;
}
  
.mx-1 {
    font-family:'GmarketSansMedium';
    width: 20%;
    margin-top: 20px;
    margin-bottom: 50px;
}

.mx-1-mobile{
    font-family:'GmarketSansMedium';
    width: 30%;
    margin-top: 10px;
    margin-bottom: 0px;
}

.mx-1-mobile-login{
    font-family:'GmarketSansMedium';
    width: 60%;
    margin-top: 50px;
    margin-bottom: 0px;
} */