.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'GmarketSansTTFMedium';
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Simple Keyboard Button */
.hg-button {
  height: 60px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: 'GmarketSansTTFMedium';
  src: url('./assets/fonts/GmarketSansTTFMedium.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSansTTFBold';
  src: url('./assets/fonts/GmarketSansTTFBold.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Medium';
  src: url('./assets/fonts/Pretendard-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-ExtraLight';
  src: url('./assets/fonts/Pretendard-ExtraLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.hot-key {
  background-color: #bad9ff !important;
  color: #000000;
}