.calcButton{
    width: 3em;
    height: 3em;
    font-size: 1.5em;
    border: none;
    border-radius: 2em;
    margin: 0.3em;
}

.calcButton:hover{
    cursor: pointer;
}

.wrapper{
    background-color: black;
    border-radius: 1em;
    padding: 1em;
    font-size: 1em;
}

.orange{
    background-color: #FF9500;
    color: white;
}

.gray{
    background-color: #505050;
    color: white;
}
.result{
    color: white;
    display: flex;
    justify-content: flex-end;
    margin-right: 1em;
    font-size:2.5em;
}
.formula{
    color: white;
    display: flex;
    justify-content: flex-end;
    margin-right: 1em;
    font-size:1.5em;
}